import { ACCESS_TOKEN, CHAT_BOT_API_TOKEN } from '../constants/constants';

const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};
const getItem = (key: string) => localStorage.getItem(key) || null;

export const getAccessToken = () => getItem(ACCESS_TOKEN) || '';

export const setAccessToken = (token: string) => {
  setItem(ACCESS_TOKEN, token);
  setItem(CHAT_BOT_API_TOKEN, token);
};

export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};
