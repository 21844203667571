import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addAssetAPI,
  deleteAssetAPI,
  deleteAssetUserAPI,
  getAssetUsers,
  getTenantAssestDataAPI,
  getTenantPermissionDetailsAPI,
  getTenantUserAssestDataAPI
} from '../apis/SSHServerManagementApi';
import { Asset, AssetUsers, PermissionData } from '../models';
import { RootState } from 'src/store/reducer';

interface SshAssetManagementState {
  assetListData: Asset[];
  loadingAssetData: boolean;
  isAssetListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  assetsUserData: AssetUsers[];
  isAssetUserUpdated: boolean;
  permissionData: PermissionData;
}

const initialState: SshAssetManagementState = {
  assetListData: [],
  loadingAssetData: false,
  isAssetListUpdated: false,
  isError: false,
  errorMessage: '',
  assetsUserData: [],
  isAssetUserUpdated: false,
  permissionData: { value: true, id: '1' }
};
export const selectorSshAssetManagementState = ({
  sshServerManagementData
}: RootState) => ({
  assetListData: sshServerManagementData?.assetListData,
  loadingAssetData: sshServerManagementData?.loadingAssetData,
  isAssetListUpdated: sshServerManagementData?.isAssetListUpdated,
  isError: sshServerManagementData?.isError,
  errorMessage: sshServerManagementData?.errorMessage,
  assetsUserData: sshServerManagementData?.assetsUserData,
  isAssetUserUpdated: sshServerManagementData?.isAssetUserUpdated,
  permissionData: sshServerManagementData?.permissionData
});
export const getTenantAssestData = createAsyncThunk(
  'sshAssetManagementData/getTenantAssestData',
  async (organization: object) => {
    const response = await getTenantAssestDataAPI(organization);
    return response;
  }
);

export const getTenantUserAssestData = createAsyncThunk(
  'sshAssetManagementData/getTenantUserAssestData',
  async (payload: object) => {
    const response = await getTenantUserAssestDataAPI(payload);
    return response;
  }
);

export const getAssetsUserData = createAsyncThunk(
  'sshAssetManagementData/getAssetsUserData',
  async (payload: any) => {
    const response = await getAssetUsers(
      payload.organizationId,
      payload.assetsId
    );
    return response;
  }
);

export const deleteAsset = createAsyncThunk(
  'sshAssetManagementData/deleteAsset',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteAssetUser = createAsyncThunk(
  'sshAssetManagementData/deleteAssetUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteAssetUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAsset = createAsyncThunk(
  'sshAssetManagementData/addAsset',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await addAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAssetDTO = (masterList) => {
  return [
    ...masterList.map((entry) => {
      if (entry.id) {
        return entry;
      }
      return {
        id: entry.asset_id,
        name: entry.asset_name,
        description: entry.asset_description
      };
    })
  ];
};

const getAssetUsersDTO = (masterList) => {
  return [
    ...masterList.map((entry) => {
      if (entry.id) {
        return entry;
      }
      return {
        id: entry.user_id,
        name: entry.asset_name,
        description: entry.asset_description,
        assetId: entry.asset_id,
        userId: entry.user_id
      };
    })
  ];
};
export const getTenantPermissionDetails = createAsyncThunk(
  'adminManagementData/getTenantPermissionDetails',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await getTenantPermissionDetailsAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const secretlice = createSlice({
  name: 'assetData',
  initialState,
  reducers: {
    setIsAssetListUpdated: (state: any, action) => {
      state.isAssetListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTenantAssestData.pending, (state) => {
        state.loadingAssetData = true;
        state.assetListData = [];
        state.permissionData = { value: true, id: '1' };
      })
      .addCase(getTenantAssestData.fulfilled, (state: any, action) => {
        state.loadingAssetData = false;
        if (action.payload) {
          let assetListData = action.payload;
          state.assetListData = getAssetDTO(assetListData);
        }
      })
      .addCase(getTenantAssestData.rejected, (state) => {
        state.loadingAssetData = false;
      })
      .addCase(getTenantUserAssestData.pending, (state) => {
        state.loadingAssetData = true;
        state.assetListData = [];
      })
      .addCase(getTenantUserAssestData.fulfilled, (state: any, action) => {
        state.loadingAssetData = false;
        if (action.payload) {
          let assetListData = action.payload;
          state.assetListData = getAssetDTO(assetListData);
        }
      })
      .addCase(getTenantUserAssestData.rejected, (state) => {
        state.loadingAssetData = false;
      })

      .addCase(addAsset.pending, (state) => {
        state.loadingAssetData = true;
      })
      .addCase(addAsset.fulfilled, (state: any, action) => {
        state.loadingAssetData = false;
        state.isAssetListUpdated = true;
      })
      .addCase(addAsset.rejected, (state, action: any) => {
        state.loadingAssetData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteAsset.pending, (state) => {
        state.loadingAssetData = true;
      })
      .addCase(deleteAsset.fulfilled, (state: any, action) => {
        state.loadingAssetData = false;
        state.isAssetListUpdated = true;
      })
      .addCase(deleteAsset.rejected, (state, action: any) => {
        state.loadingAssetData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getAssetsUserData.fulfilled, (state: any, action) => {
        state.loadingAssetData = false;
        if (action.payload) {
          let assetUserListData = action.payload;
          state.assetsUserData = getAssetUsersDTO(assetUserListData);
          state.isAssetUserUpdated = false;
        }
      })
      .addCase(deleteAssetUser.fulfilled, (state: any, action) => {
        state.loadingAssetData = false;
        state.isAssetUserUpdated = true;
      })
      .addCase(getTenantPermissionDetails.fulfilled, (state: any, action) => {
        state.loadingPermissionDetails = false;
        state.permissionData = action.payload;
      })
      .addCase(getTenantPermissionDetails.rejected, (state: any, action) => {
        state.permissionData = { value: true, id: '1' };
      });
  }
});

export const { setIsAssetListUpdated, setIsError } = secretlice.actions;
export default secretlice.reducer;
