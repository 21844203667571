import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addSecretAPI,
  deleteSecretAPI,
  getSecretMasterTemplatesDataAPI,
  getTenantSecretDataAPI
} from '../apis/SecretManagementApi';
import { Secret, SecretTemplate } from '../models';
import { RootState } from 'src/store/reducer';

interface SecretManagementState {
  secretListData: Secret[];
  secretTemplateData: SecretTemplate[];
  loadingSecretData: boolean;
  isSecretListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
}

const initialState: SecretManagementState = {
  secretListData: [],
  loadingSecretData: false,
  isSecretListUpdated: false,
  secretTemplateData: [],
  isError: false,
  errorMessage: ''
};
export const selectorSecretManagementState = ({
  secretManagementData
}: RootState) => ({
  secretListData: secretManagementData?.secretListData,
  loadingSecretData: secretManagementData?.loadingSecretData,
  isSecretListUpdated: secretManagementData?.isSecretListUpdated,
  secretTemplateData: secretManagementData?.secretTemplateData,
  isError: secretManagementData?.isError,
  errorMessage: secretManagementData?.errorMessage
});

export const getTenantSecretData = createAsyncThunk(
  'secretManagementData/getTenantSecretData',
  async (organization: object) => {
    const response = await getTenantSecretDataAPI(organization);
    return response;
  }
);

export const getSecretMasterTemplateData = createAsyncThunk(
  'secretManagementData/getSecretMasterTemplatesData',
  async (organization: object) => {
    const response = await getSecretMasterTemplatesDataAPI(organization);
    return response;
  }
);

export const addSecret = createAsyncThunk(
  'secretManagementData/addSecret',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await addSecretAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSecret = createAsyncThunk(
  'secretManagementData/deleteSecret',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteSecretAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getSecretMasterFormatedJSONList = (masterList) => {
  return [
    ...masterList.map((entry) => {
      return {
        ...entry,
        template: JSON.stringify(entry?.template, null, 4)
      };
    })
  ];
};

export const secretlice = createSlice({
  name: 'secretData',
  initialState,
  reducers: {
    setIsSecretListUpdated: (state: any, action) => {
      state.isSecretListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTenantSecretData.pending, (state) => {
        state.loadingSecretData = true;
        state.organizationData = [];
      })
      .addCase(getTenantSecretData.fulfilled, (state: any, action) => {
        state.loadingSecretData = false;
        if (action.payload) {
          let secretList = action.payload;
          state.secretListData = secretList;
        }
      })
      .addCase(getTenantSecretData.rejected, (state) => {
        state.loadingSecretData = false;
      })
      .addCase(getSecretMasterTemplateData.pending, (state) => {
        state.loadingSecretData = true;
        state.secretTemplateData = [];
      })
      .addCase(getSecretMasterTemplateData.fulfilled, (state: any, action) => {
        state.loadingSecretData = false;
        if (action.payload) {
          let secretList = action.payload;
          state.secretTemplateData =
            getSecretMasterFormatedJSONList(secretList);
        }
      })
      .addCase(getSecretMasterTemplateData.rejected, (state) => {
        state.loadingSecretData = false;
      })

      .addCase(addSecret.pending, (state) => {
        state.loadingSecretData = true;
      })
      .addCase(addSecret.fulfilled, (state: any, action) => {
        state.loadingSecretData = false;
        state.isSecretListUpdated = true;
      })
      .addCase(addSecret.rejected, (state, action: any) => {
        state.loadingSecretData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteSecret.pending, (state) => {
        state.loadingSecretData = true;
      })
      .addCase(deleteSecret.fulfilled, (state: any, action) => {
        state.loadingSecretData = false;
        state.isSecretListUpdated = true;
      })
      .addCase(deleteSecret.rejected, (state, action: any) => {
        state.loadingSecretData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const { setIsSecretListUpdated, setIsError } = secretlice.actions;
export default secretlice.reducer;
