import Header from 'src/core/header/Header';
import { APIEndpoints } from 'src/shared/constants/api-endpoints';
import {
  Config,
  apiDelete,
  apiGet,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getTenantAssestDataAPI = async (organization: any) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`organization/${organization.id}/assets`);
  return res.data;
};

export const getTenantUserAssestDataAPI = async (payload: any) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(
    `user/${payload.userId}/tenant/${payload.tenantId}/assets`
  );
  return res.data;
};

export const deleteAssetAPI = async (payload: any) => {
  const res = await apiDelete(
    `organization/${payload.tenantId}/asset/${payload.assetId}`
  );
  return res.data;
};

export const deleteAssetUserAPI = async (payload: any) => {
  const res = await apiDelete(
    `organization/${payload.tenantId}/assets/${payload.assetId}/user/${payload.userId}`
  );
  return res.data;
};

export const addAssetAPI = async (payload: any) => {
  const res = await apiPost(
    `organization/${payload.get('tenantId')}/asset`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return res.data;
};

export const getAssetUsers = async (organization: string, assetsId: string) => {
  const res = await apiGet(
    `organization/${organization}/assets/${assetsId}/users`
  );
  return res.data ?? [];
};

export const assginUserToAssets = async (
  organization: string,
  assetsId: string,
  payload
) => {
  const res = await apiPost(
    `organization/${organization}/assets/${assetsId}/user`,
    payload
  );
  return res.data;
};
export const getTenantPermissionDetailsAPI = async (payload: any) => {
  const res = await apiGet(
    `access/tenant/${payload.tenantId}/role/${payload.role}`
  );
  return res.data;
};
