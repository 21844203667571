import { FC, ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from '../header/Header';
import Sidebar from '../side-bar/Sidebar';

interface UtilityLayoutProps {
  children?: ReactNode;
}

const UtilityLayout: FC<UtilityLayoutProps> = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        className={
          theme.colors.error.main === 'dark'
            ? 'layoutbox MuiPageTitlewrapperDark '
            : 'layoutbox MuiPageTitlewrapperLight '
        }
      >
        <Header />
        <Box
          className="layout"
          sx={{
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {}
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UtilityLayout;
