import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SidebarMenus } from 'src/shared/components/index';
import {
  DEV_ENVIRONMENT,
  ROLES,
  USER_TYPE
} from 'src/shared/constants/constants';
import * as ROUTES from 'src/shared/constants/routes';
import { RootState } from 'src/store/reducer';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { selectUserState } from 'src/auth/login/services/userSlice';
const SidebarMenu = () => {
  const { loginUserRole, selectedOrganization, loginUserType } =
    useSelector(selectUserState);
  const [menuList, setMenuList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const organization = {
    pathname: ROUTES.ADMIN_LOGIN,
    subpathname: ROUTES.ORGANIZATION,
    icon: <TableChartTwoToneIcon />,
    submenuname: 'organization'
  };

  const adminManagement = {
    pathname: ROUTES.ADMIN_LOGIN,
    subpathname: ROUTES.ADMIN_MANAGEMENT,
    icon: <TableChartTwoToneIcon />,
    submenuname: 'admin_management'
  };

  const userManagement = {
    pathname: ROUTES.USER_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'user_management'
  };

  const secretManagement = {
    pathname: ROUTES.SECRET_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'secret_management'
  };

  const sshServerManagement = {
    pathname: ROUTES.SSH_SERVER_MANAGEMENT,
    subpathname: '',
    icon: <BookmarkBorderOutlinedIcon />,
    submenuname: 'ssh_server_management'
  };

  //TODO: WIP: will add logic in next realease

  const adminOperatorChatBot = {
    pathname: ROUTES.ADMIN_OPERATOR_CHAT_BOT,
    subpathname: '',
    icon: <BookmarkBorderOutlinedIcon />,
    submenuname: 'chat_bot'
  };

  useEffect(() => {
    const list = [];
    setMenuList([]);
    setAssetsList([]);
    const pathName = window.location.pathname;
    if (
      pathName === `${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}` ||
      pathName === `${ROUTES.ADMIN_LOGIN}${ROUTES.ADMIN_MANAGEMENT}` ||
      pathName === `${ROUTES.TENANT_LOGIN}${ROUTES.ORGANIZATION}`
    ) {
      list.push(organization);
      if (loginUserRole === ROLES.SUPER_ADMIN) {
        list.push(adminManagement);
      }
    }
    if (
      pathName === ROUTES.USER_MANAGEMENT ||
      pathName === ROUTES.SECRET_MANAGEMENT ||
      pathName === ROUTES.SSH_SERVER_MANAGEMENT ||
      pathName === ROUTES.ADMIN_OPERATOR_CHAT_BOT ||
      pathName.includes(ROUTES.ADMIN_OPERATOR_USER_DETAILS) ||
      pathName.includes(ROUTES.ADMIN_OPERATOR_USER_ASSETS)
    ) {
      if (
        loginUserRole === ROLES.TENANT_ADMIN ||
        loginUserRole === ROLES.SUPER_ADMIN ||
        loginUserRole === ROLES.ADMIN_OPERATOR
      ) {
        list.push(userManagement);
        list.push(secretManagement);
      } else if (loginUserRole === ROLES.TENANT_OPERATOR) {
        list.push(userManagement);
        list.push(secretManagement);
      } else if (loginUserRole === ROLES.END_USER) {
      }
      // removed for production
      if (DEV_ENVIRONMENT.toString() == 'true') {
        setAssetsList([sshServerManagement, adminOperatorChatBot]);
      } else {
        setAssetsList([sshServerManagement]);
      }

      setMenuList(list);
    } else {
      setMenuList(list);
    }
  }, [window.location.pathname, selectedOrganization]);
  return (
    <>
      <SidebarMenus menuname={''} option={menuList} />
      <SidebarMenus menuname={'assets'} option={assetsList} />
    </>
  );
};

export default SidebarMenu;
