import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getChatBotListAPI = async (payload) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`/organization/${payload.orgId}/assets?type=BOT`);
  return res.data;
};

export const getSecretKeyAPI = async (payload) => {
  const res = await apiGet(
    `/tenants/${payload.orgId}/secrets/keys?secretType=${payload.secretType}`
  );
  return res.data;
};

export const addBotAPI = async (payload) => {
  const res = await apiPost(`/tenants/${payload.orgId}/bots`, payload.obj);
  return res.data;
};

export const deleteBotAPI = async (payload) => {
  const res = await apiDelete(
    `/tenants/${payload.orgId}/bots/${payload.botId}`
  );
  return res.data;
};
export const updateBotAPI = async (payload) => {
  const res = await apiPut(
    `/tenants/${payload.orgId}/bots/${payload.botId}`,
    payload.obj
  );
  return res.data;
};
export const botStatusUpdate = async (payload) => {
  const res = await apiPatch(
    `/tenants/${payload.orgId}/bots/${payload.botId}`,
    payload.obj
  );
  return res.data;
};
// Start Bot training.
export const getStartBotTraning = async (tenantId: any, botId: any) => {
  const res = await apiGet(`tenants/${tenantId}/bots/${botId}/start-training`);
  return res.data;
};
