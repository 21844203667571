export const LOGIN = '/';
export const ERROR_PAGE = '/not-found';

export const ADMIN_LOGIN = '/admin';
export const TENANT_LOGIN = '/tenant';

export const DASHBOARD = '/dashboards';
export const TRANSACTIONS = '/transactions';
export const ORGANIZATION = '/organization';
export const ADMIN_MANAGEMENT = '/admin-management';

export const USER_MANAGEMENT = '/user-management';
export const SECRET_MANAGEMENT = '/secret-management';
export const SSH_SERVER_MANAGEMENT = '/ssh-server';
export const TERMINAL = '/iterminal';
export const ADMIN_OPERATOR_USER_ASSETS = '/admin-operator-user-asset';
export const ADMIN_OPERATOR_CHAT_BOT = '/chat-bot';
export const CHAT_BOT_CONNECT = '/chat-bot-connect';
export const FILE_MANAGER = '/file-manager';
export const ADMIN_OPERATOR_USER_DETAILS = '/admin-operator-user-details';
