import * as sidebarmenu from './sidebarmenu.json';
import * as organization from './organization.json';
import * as adminusermanagement from './adminusermanagement.json';
import * as usermanagement from './usermanagement.json';
import * as lang from './lang.json';
import * as secretmanagement from './secretmanagement.json';
import * as sshservermanagement from './sshservermanagement.json';
import * as filemanager from './filemanager.json';

const allFr = {
  lang,
  sidebarmenu,
  organization,
  adminusermanagement,
  usermanagement,
  secretmanagement,
  sshservermanagement,
  filemanager
};

export default allFr;
