import { apiGet, apiPost, apiPut } from 'src/shared/utils/api-request';

export const getFileManagerConnectionIdDataAPI = async (payload: any) => {
  const res = await apiGet(`file-manager/asset/${payload.id}`);
  return res.data;
};

export const getfilesAndFolder = async (paylaod: any) => {
  const res = await apiGet(
    `file-manager/dir/ls/${
      paylaod.connectionId
    }/path?remotedirectory=${encodeURI(paylaod.path)}`
  );
  return res.data ?? [];
};
export const uploadFileToServerAPI = async (payload: any) => {
  const res = await apiPost(
    `file-manager/${payload.connectionId}/file/upload`,
    payload.data,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return res.data;
};

export const renameItemToServerEventAPI = async (payload: any) => {
  const res = await apiPut(
    `file-manager/${payload.connectionId}/rename`,
    payload.data
  );
  return res.data;
};

export const handleCreateFileDirEventAPI = async (payload: any) => {
  const res = await apiPost(
    `file-manager/${payload.connectionId}/create-directory`,
    payload.data
  );
  return res.data;
};

export const downloadFileFromServerAPI = async (payload: any) => {
  const res = await apiPost(
    `/file-manager/${payload.connectionId}/file/download`,
    payload,
    {
      responseType: 'blob'
    }
  );
  return res;
};
