import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/core/layout/SidebarLayout';
import BaseLayout from 'src/core';
import { GuardedRoute } from './guarded-routes';
import * as MODULE from './../shared/constants/constants';
import { SuspenseLoader } from 'src/shared/components/index';
import * as ROUTES from '../shared/constants/routes';
import UtilityLayout from 'src/core/layout/UtilityLayout';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => MODULE.LOGIN));
const ErrorPage = Loader(lazy(() => MODULE.ERROR_PAGE));
const ITerminalPage = Loader(lazy(() => MODULE.I_TERMINAL));
const FileManagerPage = Loader(lazy(() => MODULE.FILE_MANAGER_MODULE));
// Dashboards

const Organazation = Loader(lazy(() => MODULE.ORGANIZATION));
const AdminManagement = Loader(lazy(() => MODULE.ADMIN_MANAGEMENT));
const UserManagement = Loader(lazy(() => MODULE.USER_MANAGEMENT));
const secretManagement = Loader(lazy(() => MODULE.SECRET_MANAGEMENT));
const sshServerManagement = Loader(lazy(() => MODULE.SSH_SERVER_MANAGEMENT));
const adminOperatorChatBot = Loader(lazy(() => MODULE.ADMIN_OPERATOR_CHAT_BOT));
const chatBotConnect = Loader(lazy(() => MODULE.CHAT_BOT_CONNECT));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout className="admin-login" />,
    children: [
      {
        path: ROUTES.ADMIN_LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: `${ROUTES.DASHBOARD}`,
        element: (
          <Navigate
            to={`${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}`}
            replace
          />
        )
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}`,
        element: <GuardedRoute component={Organazation} />
      },
      {
        path: `${ROUTES.TENANT_LOGIN}${ROUTES.ORGANIZATION}`,
        element: <GuardedRoute component={Organazation} />
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.ADMIN_MANAGEMENT}`,
        element: <GuardedRoute component={AdminManagement} />
      },
      {
        path: `${ROUTES.USER_MANAGEMENT}`,
        element: <GuardedRoute component={UserManagement} />
      },
      {
        path: `${ROUTES.USER_MANAGEMENT}${ROUTES.ADMIN_OPERATOR_USER_DETAILS}`,
        element: <GuardedRoute component={UserManagement} />
      },
      {
        path: `${ROUTES.SECRET_MANAGEMENT}`,
        element: <GuardedRoute component={secretManagement} />
      },
      {
        path: `${ROUTES.SSH_SERVER_MANAGEMENT}`,
        element: <GuardedRoute component={sshServerManagement} />
      },
      {
        path: `${ROUTES.SSH_SERVER_MANAGEMENT}${ROUTES.ADMIN_OPERATOR_USER_ASSETS}`,
        element: <GuardedRoute component={sshServerManagement} />
      },
      {
        path: `${ROUTES.ADMIN_OPERATOR_CHAT_BOT}`,
        element: <GuardedRoute component={adminOperatorChatBot} />
      },
      // removed for production
      {
        path: `${ROUTES.CHAT_BOT_CONNECT}`,
        element: <GuardedRoute component={chatBotConnect} />
      }
    ]
  },
  {
    path: '',
    element: <UtilityLayout />,
    children: [
      {
        path: `${ROUTES.FILE_MANAGER}`,
        element: <FileManagerPage />
      }
    ]
  },
  {
    path: '',
    element: <UtilityLayout />,
    children: [
      {
        path: `${ROUTES.FILE_MANAGER}`,
        element: <FileManagerPage />
      }
    ]
  },
  {
    path: ROUTES.ERROR_PAGE,
    element: <ErrorPage />
  },
  {
    path: ROUTES.TERMINAL,
    element: <ITerminalPage />
  }
  // {
  //   path: `${ROUTES.FILE_MANAGER}`,
  //   element: <FileManagerPage />
  // }
];

export default routes;
