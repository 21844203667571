import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store/reducer';
import {
  downloadFileFromServerAPI,
  getfilesAndFolder,
  uploadFileToServerAPI,
  getFileManagerConnectionIdDataAPI,
  renameItemToServerEventAPI,
  handleCreateFileDirEventAPI
} from '../apis/fileManagerAPIs';
import {
  DIR_CREATED_MESSAGE,
  ERROR_MESSAGE,
  FILE_RENAME_MESSAGE,
  FILE_UPLOAD_MESSAGE
} from '../../../shared/constants/constants';
import { FileDirectory, FileDirectoryDTO } from '../models/file-manager.model';

interface SFTPState {
  directoryData: FileDirectoryDTO;
  loadingDirectoryList: boolean;
  isDiredtoryListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  successMessage: string;
  fileManagerConnectionId: string;
}

const initialState: SFTPState = {
  directoryData: null,
  loadingDirectoryList: false,
  isDiredtoryListUpdated: false,
  isError: false,
  errorMessage: '',
  successMessage: '',
  fileManagerConnectionId: null
};
export const selectorSFTPstate = ({ sftpData }: RootState) => ({
  directoryData: sftpData?.directoryData,
  fileManagerErrorMessage: sftpData?.errorMessage,
  successMessage: sftpData?.successMessage,
  isFileManagerError: sftpData.isError,
  fileManagerConnectionId: sftpData.fileManagerConnectionId,
  isDiredtoryListUpdated: sftpData.isDiredtoryListUpdated
});

export const getFileManagerConnectionIdData = createAsyncThunk(
  'SFTP/getFileManagerConnectionIdData',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await getFileManagerConnectionIdDataAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getfilesAndFolderEvent = createAsyncThunk(
  'SFTP/getfilesAndFolderEvent',
  async (paylaod: any, { rejectWithValue }) => {
    try {
      const response = await getfilesAndFolder(paylaod);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadFileToServerEvent = createAsyncThunk(
  'SFTP/uploadFileToServerEvent',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await uploadFileToServerAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const renameItemToServerEvent = createAsyncThunk(
  'SFTP/renameItemToServerEvent',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await renameItemToServerEventAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const handleCreateFileDirEvent = createAsyncThunk(
  'SFTP/handleCreateFileDirEvent',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await handleCreateFileDirEventAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const downloadFileFromServerEvent = createAsyncThunk(
  'SFTP/downloadFileToServerEvent',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await downloadFileFromServerAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SFTPSlice = createSlice({
  name: 'SFTP',
  initialState,
  reducers: {
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    resetSFTPState: (state: any, action) => {
      state.directoryData = action.payload;
    },
    resetDirUpdateSsatuState: (state: any, action) => {
      state.isDiredtoryListUpdated = action.payload;
      state.successMessage = '';
    },
    resetFileManagerConnectionIdData: (state: any) => {
      state.fileManagerConnectionId = null;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getfilesAndFolderEvent.pending, (state) => {
        state.loadingDirectoryList = true;
      })
      .addCase(getfilesAndFolderEvent.fulfilled, (state: any, action) => {
        state.loadingDirectoryList = false;
        if (action.payload) {
          state.directoryData = action.payload;
        }
      })
      .addCase(getfilesAndFolderEvent.rejected, (state: any, action: any) => {
        state.loadingDirectoryList = false;
        state.isError = true;
        if (
          action.payload.statusCode === 403 ||
          action.payload.statusCode === 503
        ) {
          state.errorMessage = action.payload.message;
        } else {
          state.errorMessage = ERROR_MESSAGE;
        }
      })
      .addCase(uploadFileToServerEvent.pending, (state) => {
        state.loadingDirectoryList = true;
      })
      .addCase(uploadFileToServerEvent.fulfilled, (state: any, action) => {
        state.isDiredtoryListUpdated = true;
        state.successMessage = FILE_UPLOAD_MESSAGE;
      })
      .addCase(uploadFileToServerEvent.rejected, (state: any, action: any) => {
        state.loadingDirectoryList = false;
        state.isError = true;
        state.errorMessage = ERROR_MESSAGE;
      })
      .addCase(renameItemToServerEvent.pending, (state) => {
        state.loadingDirectoryList = true;
      })
      .addCase(renameItemToServerEvent.fulfilled, (state: any, action) => {
        state.isDiredtoryListUpdated = true;
        state.successMessage = FILE_RENAME_MESSAGE;
      })
      .addCase(renameItemToServerEvent.rejected, (state: any, action: any) => {
        state.loadingDirectoryList = false;
        state.isError = true;
        state.errorMessage = ERROR_MESSAGE;
      })
      .addCase(handleCreateFileDirEvent.pending, (state) => {
        state.loadingDirectoryList = true;
      })
      .addCase(handleCreateFileDirEvent.fulfilled, (state: any, action) => {
        state.isDiredtoryListUpdated = true;
        state.successMessage = DIR_CREATED_MESSAGE;
      })
      .addCase(handleCreateFileDirEvent.rejected, (state: any, action: any) => {
        state.loadingDirectoryList = false;
        state.isError = true;
        state.errorMessage = ERROR_MESSAGE;
      })
      .addCase(getFileManagerConnectionIdData.pending, (state) => {
        state.loadingAssetData = true;
        state.fileManagerConnectionId = null;
      })
      .addCase(
        getFileManagerConnectionIdData.fulfilled,
        (state: any, action) => {
          state.loadingAssetData = false;
          if (action.payload) {
            let fileManagerConnectionId = action.payload;
            state.fileManagerConnectionId = fileManagerConnectionId;
          }
        }
      )
      .addCase(
        getFileManagerConnectionIdData.rejected,
        (state, action: any) => {
          state.loadingAssetData = false;
          state.isError = true;
          state.errorMessage = ERROR_MESSAGE;
        }
      );
  }
});

export const {
  setIsError,
  resetSFTPState,
  resetFileManagerConnectionIdData,
  resetDirUpdateSsatuState
} = SFTPSlice.actions;
export default SFTPSlice.reducer;
