import { Config, apiDelete, apiGet, apiPost, apiPut } from 'src/shared/utils/api-request';

export const getTenantSecretDataAPI = async (organization: any) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`tenants/${organization.id}/secrets`);
  return res.data;
};

export const getSecretMasterTemplatesDataAPI = async (organization: any) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`tenants/${organization.id}/secrets/templates`);
  return res.data;
};

export const addSecretAPI = async (payload: any) => {
  const res = await apiPost(`tenants/${payload.tenantId}/secrets`, payload);
  return res.data;
};

export const deleteSecretAPI = async (payload: any) => {
  const res = await apiDelete(`tenants/${payload.tenantId}/secrets/${payload.secretId}`);
  return res.data;
};

export const updateOrganizationAPI = async (organization: any) => {
  const res = await apiPut('/admin/tenant/' + organization.id, { name: organization.tenantName });
  return res.data;
};
