import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import transactionReducer from 'src/modules/Transactions/services/transaction.service';
import organizationReducer from 'src/modules/Organization/services/organization.service';
import adminUserDataReducer from 'src/modules/AdminManagement/services/adminManagement.service';
import userManagementDataReducer from 'src/modules/UserManagement/services/userManagement.service';
import secretManagementDataReducer from 'src/modules/SecretManagement/services/secretManagement.service';
import sshServerManagementDataReducer from 'src/modules/SSHServerManagement/services/sshServerManagement.service';
import userReducer from 'src/auth/login/services/userSlice';
import storage from 'redux-persist/lib/storage';
import botManagementReducer from './../../modules/ChatBot/services/Chatbot.service';
import sftpReducer from 'src/modules/FileManager/services/file-manager.service';

const combinedReducer = combineReducers({
  user: userReducer,
  organizationsData: organizationReducer,
  adminUserData: adminUserDataReducer,
  transactionData: transactionReducer,
  userData: userReducer,
  userManagementData: userManagementDataReducer,
  secretManagementData: secretManagementDataReducer,
  sshServerManagementData: sshServerManagementDataReducer,
  botManagementData: botManagementReducer,
  sftpData: sftpReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
